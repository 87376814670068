<template>
  <Form class="py-14" @submit="handleSubmit">
    <h1 class="font-bold text-center text-3xl">
      Sign In {{ isPasswordLoginDisabled ? '' : 'or Request Invite' }}
    </h1>
    <p class="text-center font-semibold">to continue</p>

    <TextInput
      v-if="!isPasswordLoginDisabled"
      v-model="email"
      label="Email"
      placeholder="Email"
      :rules="[isRequired, isEmail]"
      @update:model-value="errorMessage = ''"
    />

    <alert v-if="errorMessage" class="mt-4" :type="AlertType.ERROR">
      {{ errorMessage }}
    </alert>

    <Btn
      v-if="!isPasswordLoginDisabled"
      :size="BtnSize.LG"
      :variant="BtnVariant.FILLED"
      :color="BtnColor.PRIMARY"
      class="w-full mt-10"
      type="submit"
    >
      Next
    </Btn>
    <ExternalAuthProviders
      @error="(error) => setErrorMessageFromError(error)"
      @link-accounts="emit('change-type', SelfRegisterType.ENTER_PASSWORD)"
    />
    <div
      v-if="
        organization &&
        organization.hasLoginScreenAdditionalMessage &&
        organization.loginScreenAdditionalMessage
      "
      class="text-xs text-center mt-8"
      v-html="organization.loginScreenAdditionalMessage"
    />
  </Form>
</template>

<script setup lang="ts">
import { ref, useOrganizationDomain } from '#imports';
import { Form } from 'vee-validate';
import { TextInput } from '~/components/inputs/text-input';
import { Btn, BtnSize, BtnColor, BtnVariant } from '~/components/btn';
import { isRequired, isEmail } from '~/utils/validators';
import { useLoader } from '~/composables/use-loader';
import { useVModel } from '@vueuse/core';
import {
  apiCheckEmail,
  EmailStatus,
  SelfRegisterType,
  apiResendInvitation,
  apiActivateUser,
} from '~/api/auth';
import { Alert, AlertType } from '~/components/alert';
import { ExternalAuthProviders } from '~/pages/auth/_components/external-auth-providers';
import { useOrganizationStore } from '~/store/organization';

const { organization } = storeToRefs(useOrganizationStore());

const { t } = useI18n();

type Props = {
  modelValue: string;
};
const props = defineProps<Props>();
const { query } = useRoute();
const invitationCode = getFirstQueryParam(query.code);
const errorMessage = ref('');

const isPasswordLoginDisabled =
  organization?.value?.externalIdentityProviderData
    ?.passwordRegistrationDisabled || false;
const emit = defineEmits<{
  (e: 'change-type', value: SelfRegisterType): void;
  (e: 'update:modelValue', value: Props['modelValue']): void;
}>();

const email = useVModel(props, 'modelValue', emit);

const emailStatusSelfRegisterMap: Record<EmailStatus, SelfRegisterType> = {
  [EmailStatus.UNKNOWN]: SelfRegisterType.REQUEST_INVITE,
  [EmailStatus.NEW]: SelfRegisterType.REGISTER_YOURSELF,
  [EmailStatus.PENDING]: SelfRegisterType.RESEND_WELCOME_INVITE,
  [EmailStatus.REGISTERED]: SelfRegisterType.ENTER_PASSWORD,
};

const setErrorMessageFromError = (error: unknown) => {
  errorMessage.value = organization.value?.externalIdentityProviderData
    ? t('auth.sso-error')
    : (error?.data?.message ?? 'Auth failed');
};

const handleSubmit = async () => {
  await useLoader({
    action: async () => {
      if (invitationCode) {
        await apiActivateUser({ invitationCode });
      }
      const { emailStatus } = await apiCheckEmail({ email: email.value });
      if (emailStatus === EmailStatus.PENDING) {
        await apiResendInvitation({ email: email.value });
      }
      emit('change-type', emailStatusSelfRegisterMap[emailStatus]);
    },
    onError(err: unknown) {
      setErrorMessageFromError(err);
    },
  });
};

const organizationDomain = useOrganizationDomain();

const isMarketmylaserDomain = organizationDomain === 'marketmylaser.com';
</script>
